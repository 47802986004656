import React from 'react';
import styles from './Agenda.module.css';

const Agenda: React.FC = () => {
    const agendaItems = [
        // { time: '12:30', title: 'Pradžia', description: 'Vilnius' },
        { time: '12:30', title: 'Pradžia', description: 'Antakalnio g. 27, Vilnius', 
            url: 'https://maps.app.goo.gl/Bn35hMzMNGcgRQCx6' },
        // { time: '13:30', title: 'Užkandžiai', description: 'Joanitų bažnyčios kiemas' },
        { time: '16:16', title: 'Atvykimas', description: 'Farmers Circle',
            url: 'https://maps.app.goo.gl/LgSJHfnkTwDhkkuR7'},
        // { time: '16:45', title: 'Veikla I', description: 'Farmer circle' },
        // { time: '18:00', title: 'Vakarienė', description: 'Farmer circle' },
        // { time: '19:30', title: 'Veikla II', description: 'Farmer circle' },
        // { time: '20:30', title: 'Tortas', description: 'Farmer circle' },
        // { time: '21:00', title: 'Šokiai', description: 'Farmer circle' },
        { time: '23:00', title: 'Pabaiga', description: 'Namų link', url: 'https://youtu.be/6F5IDAhkN3w?si=S0LyIVsIrdW5JJa3&t=59' }
    ];

    return (
        <div>
            <h2>Gegužės 17 programa</h2>
            {/* <p className={styles.paragraph}>Gegužės 17d</p> */}
            <div className={styles.agendacontainer}>
                <ul className={styles.agendalist}>
                    {agendaItems.map((item, index) => (
                        <li key={index} className={styles.agendaitem}>
                            <div className={styles.agendatime}>{item.time}</div>
                            <div className={styles.agendadetails}>
                                <h4>{item.title}</h4>
                                <a href={item.url} target="_blank">{item.description}</a>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <p>Daugiau informacijos netrukus!</p>
        </div>
    );
};

export default Agenda;